.Header{
  position: fixed;
  width: 100vw;
  height: 96px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  animation: drop-in 1s ease;
  z-index: 998;
  background: linear-gradient(#3300a6 40%, #3300a600 100%); 
}

.Header > img {
  width: 64px;
  margin: 16px;
  cursor: pointer;
  border-radius: 50%;
  background: rgba(0,0,0,.24);
  box-shadow: 0px 0px 0px 8px rgba(0,0,0,.12);
  transition: all .2s;
  z-index: 999;
}

.Header > img:hover{
  width: 66px;
  margin: 16px 15px;
  animation: shadow-pulse .6s infinite;
}

.Header button {
  background: 0;
  border: 0;
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 1px;
  margin: 8px 16px;
  cursor: pointer;
  transition: all .1s;
  text-align: right;
}

.Header > button:hover{
  font-weight: 500;
}

#Menu{
  background: 0;
  width: 48px;
  margin-left: auto;
  box-shadow: none;
}

.Header > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.24);
}

.Header > div > div {
  position: absolute;
  top: 84px;
  right: 8px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  animation: drop-in .4s ease;
  z-index: 999;
}

.Header > div > div > button {
  color: #3300a6;
  font-weight: 600;
  letter-spacing: 0;
  padding: 16px 0px;
  border-bottom: 1px solid #3300a6;
  background: none;
}

.Header > div > div > button:last-child {
  border-bottom: 0;
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08);
  }
  50% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08);
  }
}

@keyframes drop-in {
  from{
    transform: translateY(-100%);
  }
  to{
    transform: translateY(0%);
  }
}