.What{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 120px;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;
  animation: slide-in 1s ease;
}

.What > h1 {
  font-size: 64px;
  font-weight: 900;
  text-align: left;
  position: relative;
}

.What > h1::after{
  content:'';
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 8px;
  width: 64px;
  background: #fff;
}

.What > p {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.8;
}

.What > div {
  position: relative;
  display: inline-block;
  margin: 32px;
  box-sizing: border-box;
  vertical-align: top;
  width: 25%;
}

.What > div > img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(100%);
  transition: all .2s;
}

.What > div > img:hover {
  filter: none;
}

.What > div > h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin: 8px 0px;
}

.What > div > h6 {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}

@media only screen and (max-width: 800px){
  .What{
    padding: 32px;
    height: auto;
    min-height: 100vh;
  }
  .What > h1 {
    font-size: 48px;
  }
  .What > div {
    width: calc(100% - 62px);
  }
}