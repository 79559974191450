.Who{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 120px;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;
  animation: slide-in 1s ease;
}

.Who > h1 {
  font-size: 64px;
  font-weight: 900;
  text-align: left;
  position: relative;
}

.Who > h1::after{
  content:'';
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 8px;
  width: 64px;
  background: #fff;
}

.Who > p {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.6;
}

.Who > div {
  position: relative;
  display: inline-block;
  margin: 32px;
  vertical-align: top;
  width: 200px;
}

.Who > div > a > img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(100%);
  cursor: pointer;
  transition: all .2s;
}

.Who > div > a > img:hover {
  filter: none;
}

.Who > div > h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-top: 8px;
}

.Who > div > h6 {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.Who > div > p {
  font-size: 14px;
  font-weight: 200;
  margin: 0;
  line-height: 1.2;
  margin-top: 8px;
}

@media only screen and (max-width: 800px){
  .Who{
    padding: 32px;
    height: auto;
    min-height: 100vh;
  }
  .Who > h1 {
    font-size: 48px;
  }
}