.More{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  padding: 120px;
  overflow-y : auto;
  animation: slide-in 1s ease;
}

.More > h1 {
  font-size: 64px;
  font-weight: 900;
  text-align: left;
  position: relative;
}

.More > h1::after{
  content:'';
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 8px;
  width: 64px;
  background: #fff;
}

.More > p {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.8;
}

.More > form {
  text-align: center;
  width: 100%;
}

.More > form > label > input, .More > form > label > textarea {
  width: 100%;
  max-width: 600px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 8px 0px;
  padding: 16px;
  border: 0;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.More > form > button {
  background: none;
  border: 3px solid #eee;
  border-radius: 8px;
  padding: 12px 32px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  cursor: pointer;
  transition: all .2s;
  margin: 16px;
}

.More > form > button:hover{
  padding: 13px 36px;
}

@media only screen and (max-width: 800px) {
  .More{
    padding: 32px;
    height: auto;
    min-height: 100vh;
  }
  .More > h1 {
    font-size: 48px;
  }
}