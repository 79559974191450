@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900);
.Who{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 120px;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;
  -webkit-animation: slide-in 1s ease;
          animation: slide-in 1s ease;
}

.Who > h1 {
  font-size: 64px;
  font-weight: 900;
  text-align: left;
  position: relative;
}

.Who > h1::after{
  content:'';
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 8px;
  width: 64px;
  background: #fff;
}

.Who > p {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.6;
}

.Who > div {
  position: relative;
  display: inline-block;
  margin: 32px;
  vertical-align: top;
  width: 200px;
}

.Who > div > a > img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.Who > div > a > img:hover {
  -webkit-filter: none;
          filter: none;
}

.Who > div > h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-top: 8px;
}

.Who > div > h6 {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

.Who > div > p {
  font-size: 14px;
  font-weight: 200;
  margin: 0;
  line-height: 1.2;
  margin-top: 8px;
}

@media only screen and (max-width: 800px){
  .Who{
    padding: 32px;
    height: auto;
    min-height: 100vh;
  }
  .Who > h1 {
    font-size: 48px;
  }
}
.Splash{
  width: 100vw;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
}

.Splash > img {
  position: absolute;
  width: 32vw;
  top: 32px;
  right: 32px;
  border-radius: 50%;
  background: rgba(0,0,0,.24);
  -webkit-animation: shadow-pulse-splash 1.6s infinite ease-out, fade-in 2s ease;
          animation: shadow-pulse-splash 1.6s infinite ease-out, fade-in 2s ease;;
  z-index: 999;
}

.Splash > h1 {
  position: absolute;
  bottom: 120px;
  left: 64px;
  font-size: 96px;
  font-weight: 900;
  -webkit-animation: slide-in 1s ease;
          animation: slide-in 1s ease;
}

.Splash > p {
  position: absolute;
  bottom: 96px;
  left: 64px;
  font-size: 37px;
  font-weight: 100;
  -webkit-animation: slide-in 1s ease;
          animation: slide-in 1s ease;
}

@-webkit-keyframes shadow-pulse-splash {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08), 0 0 0 0px rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0px 0px 0 90px rgba(0, 0, 0, 0), 0px 0px 0 180px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse-splash {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08), 0 0 0 0px rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0px 0px 0 90px rgba(0, 0, 0, 0), 0px 0px 0 180px rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width: 800px){
  .Splash{
    text-align: center;
    height: calc(100vh - 160px);
    margin-top: 160px;
  }
  .Splash > img {
    position: relative;
    width: 64%;
    top: auto;
    right: auto;
  }
  .Splash > h1 {
    position: relative;
    bottom: auto;
    left: auto;
    font-size: 64px;
    font-weight: 900;
    margin: 64px 0px 8px 0px;
  }
  .Splash > p {
    position: relative;
    bottom: auto;
    left: auto;
    font-size: 24px;
    font-weight: 100;
    margin-top: 0;
  }
}
.What{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 120px;
  display: inline-block;
  vertical-align: top;
  overflow-y: auto;
  -webkit-animation: slide-in 1s ease;
          animation: slide-in 1s ease;
}

.What > h1 {
  font-size: 64px;
  font-weight: 900;
  text-align: left;
  position: relative;
}

.What > h1::after{
  content:'';
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 8px;
  width: 64px;
  background: #fff;
}

.What > p {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.8;
}

.What > div {
  position: relative;
  display: inline-block;
  margin: 32px;
  box-sizing: border-box;
  vertical-align: top;
  width: 25%;
}

.What > div > img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transition: all .2s;
  transition: all .2s;
}

.What > div > img:hover {
  -webkit-filter: none;
          filter: none;
}

.What > div > h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin: 8px 0px;
}

.What > div > h6 {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}

@media only screen and (max-width: 800px){
  .What{
    padding: 32px;
    height: auto;
    min-height: 100vh;
  }
  .What > h1 {
    font-size: 48px;
  }
  .What > div {
    width: calc(100% - 62px);
  }
}
.More{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  padding: 120px;
  overflow-y : auto;
  -webkit-animation: slide-in 1s ease;
          animation: slide-in 1s ease;
}

.More > h1 {
  font-size: 64px;
  font-weight: 900;
  text-align: left;
  position: relative;
}

.More > h1::after{
  content:'';
  position: absolute;
  bottom: -12px;
  left: 0;
  height: 8px;
  width: 64px;
  background: #fff;
}

.More > p {
  font-size: 16px;
  text-align: left;
  font-weight: 300;
  line-height: 1.8;
}

.More > form {
  text-align: center;
  width: 100%;
}

.More > form > label > input, .More > form > label > textarea {
  width: 100%;
  max-width: 600px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 8px 0px;
  padding: 16px;
  border: 0;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.More > form > button {
  background: none;
  border: 3px solid #eee;
  border-radius: 8px;
  padding: 12px 32px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all .2s;
  transition: all .2s;
  margin: 16px;
}

.More > form > button:hover{
  padding: 13px 36px;
}

@media only screen and (max-width: 800px) {
  .More{
    padding: 32px;
    height: auto;
    min-height: 100vh;
  }
  .More > h1 {
    font-size: 48px;
  }
}
.Header{
  position: fixed;
  width: 100vw;
  height: 96px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  -webkit-animation: drop-in 1s ease;
          animation: drop-in 1s ease;
  z-index: 998;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, #3300a6), to(#3300a600));
  background: linear-gradient(#3300a6 40%, #3300a600 100%); 
}

.Header > img {
  width: 64px;
  margin: 16px;
  cursor: pointer;
  border-radius: 50%;
  background: rgba(0,0,0,.24);
  box-shadow: 0px 0px 0px 8px rgba(0,0,0,.12);
  -webkit-transition: all .2s;
  transition: all .2s;
  z-index: 999;
}

.Header > img:hover{
  width: 66px;
  margin: 16px 15px;
  -webkit-animation: shadow-pulse .6s infinite;
          animation: shadow-pulse .6s infinite;
}

.Header button {
  background: 0;
  border: 0;
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 1px;
  margin: 8px 16px;
  cursor: pointer;
  -webkit-transition: all .1s;
  transition: all .1s;
  text-align: right;
}

.Header > button:hover{
  font-weight: 500;
}

#Menu{
  background: 0;
  width: 48px;
  margin-left: auto;
  box-shadow: none;
}

.Header > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.24);
}

.Header > div > div {
  position: absolute;
  top: 84px;
  right: 8px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  -webkit-animation: drop-in .4s ease;
          animation: drop-in .4s ease;
  z-index: 999;
}

.Header > div > div > button {
  color: #3300a6;
  font-weight: 600;
  letter-spacing: 0;
  padding: 16px 0px;
  border-bottom: 1px solid #3300a6;
  background: none;
}

.Header > div > div > button:last-child {
  border-bottom: 0;
}

@-webkit-keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08);
  }
  50% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08);
  }
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08);
  }
  50% {
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08);
  }
}

@-webkit-keyframes drop-in {
  from{
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to{
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes drop-in {
  from{
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to{
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
body{
  margin:0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  background: #3300a6;
  color: #fff;
}

button{
  color: #fff;
}

h1, h2, h3, h4, h5, h6, img, p {
  z-index: 1;
}

.App {
  text-align: center;
  width: 200vw;
  height: 100vh;
}

@media only screen and (max-width: 800px) {
  body{
    overflow: auto;
  }
  .App{
    width: 100vw;
  }
}


@-webkit-keyframes slide-in {
  from{
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  to{
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}


@keyframes slide-in {
  from{
    opacity: 0;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  to{
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}


@-webkit-keyframes drop-in {
  from{
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to{
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}


@keyframes drop-in {
  from{
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  to{
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}


@-webkit-keyframes fade-in {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


@keyframes fade-in {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


