.Splash{
  width: 100vw;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
}

.Splash > img {
  position: absolute;
  width: 32vw;
  top: 32px;
  right: 32px;
  border-radius: 50%;
  background: rgba(0,0,0,.24);
  animation: shadow-pulse-splash 1.6s infinite ease-out, fade-in 2s ease;;
  z-index: 999;
}

.Splash > h1 {
  position: absolute;
  bottom: 120px;
  left: 64px;
  font-size: 96px;
  font-weight: 900;
  animation: slide-in 1s ease;
}

.Splash > p {
  position: absolute;
  bottom: 96px;
  left: 64px;
  font-size: 37px;
  font-weight: 100;
  animation: slide-in 1s ease;
}

@keyframes shadow-pulse-splash {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.08), 0 0 0 0px rgba(0, 0, 0, 0.16);
  }
  100% {
    box-shadow: 0px 0px 0 90px rgba(0, 0, 0, 0), 0px 0px 0 180px rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width: 800px){
  .Splash{
    text-align: center;
    height: calc(100vh - 160px);
    margin-top: 160px;
  }
  .Splash > img {
    position: relative;
    width: 64%;
    top: auto;
    right: auto;
  }
  .Splash > h1 {
    position: relative;
    bottom: auto;
    left: auto;
    font-size: 64px;
    font-weight: 900;
    margin: 64px 0px 8px 0px;
  }
  .Splash > p {
    position: relative;
    bottom: auto;
    left: auto;
    font-size: 24px;
    font-weight: 100;
    margin-top: 0;
  }
}