@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900');

body{
  margin:0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  background: #3300a6;
  color: #fff;
}

button{
  color: #fff;
}

h1, h2, h3, h4, h5, h6, img, p {
  z-index: 1;
}

.App {
  text-align: center;
  width: 200vw;
  height: 100vh;
}

@media only screen and (max-width: 800px) {
  body{
    overflow: auto;
  }
  .App{
    width: 100vw;
  }
}


@keyframes slide-in {
  from{
    opacity: 0;
    transform: translateX(-20px);
  }
  to{
    opacity: 1;
    transform: translateX(0px);
  }
}


@keyframes drop-in {
  from{
    opacity: 0;
    transform: translateY(-20px);
  }
  to{
    opacity: 1;
    transform: translateY(0px);
  }
}


@keyframes fade-in {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

